
var relatedPostPage = 1;
var relatedPostCategory = null;

jQuery(document).ready(function($) {

    if(!$('#relatedPostsContainer').length) return;

    filterPosts();

    $('[data-category]').on('click', function(event) {
        event.preventDefault();
        relatedPostCategory = $(this).data('category');
        relatedPostPage = 1;
        UIkit.dropdown($(this).parents('.uk-dropdown ')[0]).hide();
        filterPosts();
    });

    $('body').on('click', '[data-page]', function(event) {
        event.preventDefault();
        relatedPostPage = $(this).data('page');
        filterPosts();
    });
    
    function filterPosts() {
        $.ajax({
            url: '/wp-json/wlcfc/related_posts',
            method: 'GET',
            data: { 
                page: relatedPostPage,
                category: relatedPostCategory
            },
            success: function(response) {
                $('#relatedPostsContainer').empty();

                if(!response || !response.posts) {
                    $('#relatedPostsContainer').html($('#relatedPostEmptyTemplate > *').clone());
                }

                for(var i in response.posts) {
                    var postData = response.posts[i];
    
                    var postEl = $('#relatedPostTemplate > *').clone();
                    postEl.find('[data-permalink]')
                        .attr('href', postData.permalink)
                        .attr('title', postData.title);
                    postEl.find('[data-image]').attr('src', postData.image).attr('alt', postData.image_alt);
                    postEl.find('[data-title]').text(postData.title);
                    postEl.find('[data-excerpt]').replaceWith(postData.excerpt);
    
                    $('#relatedPostsContainer').append(postEl);
                }

                if(response.pagination && response.pagination.pages) {
                    $('#relatedPostPagination').empty();

                    var pagination = response.pagination;

                    if(pagination.previous)
                        $('#relatedPostPagination').append('<li><a href="#" data-page="' + pagination.previous + '"><span uk-pagination-previous></span></a></li>');

                    if(pagination.pages) {
                        for(var i in pagination.pages) {
                            var num = pagination.pages[i];
                            if(!isNaN(num)) {
                                if(pagination.current == num) {
                                    $('#relatedPostPagination').append('<li class="uk-active"><span>' + num + '</span></li>');
                                } else {
                                    $('#relatedPostPagination').append('<li><a href="#" data-page="' + num + '">' + num + '</a></li>');
                                }
                            } else {
                                $('#relatedPostPagination').append('<li class="uk-disabled"><span>...</span></li>');
                            }
                        }
                    }
                        
                    if(pagination.next)
                        $('#relatedPostPagination').append('<li><a href="#" data-page="' + pagination.next + '"><span uk-pagination-next></span></a></li>');            
                }
            }
        });
    }
});
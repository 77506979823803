jQuery(document).ready(function($) {

    $('#mobile-menu .menu-item-has-children > a').on('click', function(event) {
        event.preventDefault();
        if($(this).parent().hasClass('open')) {
            return window.location = $(this).attr('href');
        }
        $('#mobile-menu .menu-item-has-children').removeClass('open');
        $(this).parent().addClass('open');
    });

});